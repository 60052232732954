import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '../stores/auth';
import { computed, watch } from 'vue';
import gql from 'graphql-tag';
import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable';
import { organizationsClient } from '../../ApolloClient';


const GET_CLAIMS = gql`
query Query {
  roles
}
`;

/*Possible roles:
Admin,
Money,
Nuke,
PriceOverride,
Reporting,
UsePreviousCreditCard
*/

//Apollo client with id organizations not found. Use provideApolloClient() if you are outside of a component setup.

//set default apollo client

provideApolloClient( organizationsClient );

const isProducer = ref( false );

const { result: claims, load: loadClaims, refetch: refetchClaims, onResult: onClaimsLoaded } = useLazyQuery( GET_CLAIMS, null );

const hasRoles = ref( false );

onClaimsLoaded( (  result  ) => {

    if (result?.data?.roles)
    {
        if (result.data.roles.indexOf('ProducerAccountAdmin') > -1){
            isProducer.value = true;
        }
        hasRoles.value = true;
    }

} );


export const useTabsStore = defineStore( 'tabs', () =>
{
    const Dashboard = markRaw( defineAsyncComponent( () => import( '@/components/Dashboard.vue' ) ) );
    const AccountLookup = markRaw( defineAsyncComponent( () => import( '@/components/AccountLookup.vue' ) ) );
    const ProducerRoyalties = markRaw( defineAsyncComponent( () => import( '@/components/ProducerRoyalties.vue' ) ) );

    const tabs = ref( [] );

    loadClaims();
    watch( hasRoles, ( ) =>{
        if ( hasRoles.value && isProducer.value ){
            tabs.value = [
                {
                    id: generateId(),
                    name: 'Royalties',
                    component: ProducerRoyalties,
                    props: {}
                }
            ]
        }
        else if ( hasRoles.value && !isProducer.value ){
            tabs.value = [
                {
                    id: generateId(),
                    name: 'Account Lookup',
                    component: AccountLookup,
                    props: {}
                }
            ]
        
        }
    }
    );

    const nolan = ref( false );
    const chandler = ref( false );

    const activeTab = ref( 0 );

    function generateId ()
    {
        return uuidv4();
    }

    function setActiveTab ( tabNumber )
    {
        activeTab.value = tabNumber;
    }

    function addTab ( tab )
    {
        tabs.value.push( tab );
    }

    function removeTab ( tab )
    {
        tabs.value.splice( tab, 1 );
    }

    function setIsNolan ()
    {
        nolan.value = true;
    }

    function setIsChandler ()
    {
        chandler.value = true;
    }

    return { tabs, activeTab, setActiveTab, addTab, generateId, removeTab, setIsNolan, nolan, setIsChandler, chandler };
}
);