import './assets/main.css';

import { createApp, provide } from 'vue';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import App from './App.vue';
import router from './router';
import { ApolloClients } from '@vue/apollo-composable';
import
	{
		organizationsClient,
		productsClient,
		adminClient,
	} from '../ApolloClient.js';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp( App );
const pinia = createPinia();
app.use( pinia );
app.use( PrimeVue );
app.use( router );
app.use( ConfirmationService );
app.use( DialogService );
app.use( ToastService );
app.directive( 'tooltip', Tooltip );

app.provide( ApolloClients, {
	organizations: organizationsClient,
	products: productsClient,
	admin: adminClient,
} );

app.mount( '#app' );
