import { defineStore } from 'pinia';
import axios from 'axios';
import gql from 'graphql-tag';
import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable';
import { organizationsClient } from '../../ApolloClient';


/**
 * @file Auth Store
 * @module stores/auth
 * @desc This module contains the definition of the auth store, which manages authentication-related state and actions.
 */

/**
 * @typedef {Object} AuthStoreState
 * @property {string} iguid - The iguid value.
 * @property {string} accountguid - The accountguid value.
 * @property {boolean} loggedIn - Indicates whether the user is logged in or not.
 */

/**
 * @typedef {Object} AuthStoreActions
 * @property {Function} exchangeToken - Exchanges the refresh token for a new access token.
 * @property {Function} checkAuth - Checks the authentication status and performs necessary actions.
 * @property {Function} getCookie - Retrieves the value of a cookie by name.
 * @property {Function} setNewTokens - Sets new access and refresh tokens.
 * @property {Function} getAccessToken - Retrieves the current access token.
 * @property {Function} getRefreshToken - Retrieves the current refresh token.
 * @property {Function} logout - Logs out the user by clearing tokens and setting loggedIn to false.
 */

/**
 * @function useAuthStore
 * @desc Defines the auth store.
 * @param {Object} options - The options for defining the store.
 * @param {string} options.id - The unique identifier for the store.
 * @param {Function} options.state - The function that returns the initial state of the store.
 * @param {AuthStoreActions} options.actions - The actions of the store.
 * @returns {AuthStoreState & AuthStoreActions} The auth store instance.
 */

const GET_CLAIMS = gql`
query Query {
  roles
}
`;

/*Possible roles:
Admin,
Money,
Nuke,
PriceOverride,
Reporting,
UsePreviousCreditCard
*/

//Apollo client with id organizations not found. Use provideApolloClient() if you are outside of a component setup.

//set default apollo client

provideApolloClient( organizationsClient );

const { result: claims, load: loadClaims, refetch: refetchClaims, onResult: onClaimsLoaded } = useLazyQuery( GET_CLAIMS, null );

onClaimsLoaded( (  result  ) => {
    if ( result?.data?.roles )
    {
        useAuthStore().roles = result.data.roles;
    }
} );

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        iguid: '',
        accountguid: '',
        loggedIn: false,
        roles: [],
    }),
    actions: {
        async exchangeToken(accessToken, refreshToken, accessName, refreshName) {
            let domain = '.easyworship.com';
            if (window.location.hostname === 'localhost') {
                domain = 'localhost';
            }
            try {
                const response = await axios.post('https://ew-auth-api.azurewebsites.net/Authentication/exchange-token', {
                    appKey: import.meta.env.VITE_APP_KEY,
                    refreshToken: refreshToken,
                });
                console.log(JSON.stringify(response.data))
                document.cookie = `${accessName}=${response.data.accessToken};expires=${new Date(response.data.accessTokenExpiration).toUTCString()};domain=${domain};path=/`;
                document.cookie = `${refreshName}=${response.data.refreshToken};expires=${new Date(response.data.refreshTokenExpiration).toUTCString()};domain=${domain};path=/`;
                document.cookie = `accessTokenExpiration=${response.data.accessTokenExpiration};expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=${domain};path=/`;
                document.cookie = `refreshTokenExpiration=${response.data.refreshTokenExpiration};expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=${domain};path=/`;
                return response.data.accessToken;
            } catch (error) {
                document.cookie = `${refreshName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domain};path=/;`;
                return accessToken;
            }
        },
        async checkAuth() {
            let accessName = import.meta.env.VITE_APP_KEY;
            let refreshName = 'refreshToken79B25C2A49FE4E0B9ED55184F8921788';
            let refreshToken = this.getCookie(refreshName);
            let accessToken = this.getCookie(accessName);
            let accessTokenExpiration = this.getCookie('accessTokenExpiration');
            let refreshTokenExpiration = this.getCookie('refreshTokenExpiration');

            //check if cookies are expired
            if (accessTokenExpiration === undefined) {
                accessToken = undefined;
            }

            if (accessTokenExpiration !== undefined && new Date(accessTokenExpiration) < new Date()) {
                console.log("Access token expired.")
                accessToken = undefined;
            }
            if (refreshTokenExpiration !== undefined && new Date(refreshTokenExpiration) < new Date()) {
                console.log("Refresh token expired.")
                refreshToken = undefined;
            }

            if (accessToken !== undefined) {
                this.loggedIn = true;
                //load claims
                loadClaims();
            } 
            else if (refreshToken === undefined && accessToken === undefined) {
                console.log("No tokens found. Returning to login....")
                this.loggedIn = false;
                //go to auth
                this.goToAuth();
            }
            else if (refreshToken !== undefined && accessToken === undefined) {
                accessToken = await this.exchangeToken(accessToken, refreshToken, accessName, refreshName);
                console.log("New Access Token: ", accessToken)
                if (accessToken !== undefined) {
                    this.loggedIn = true;
                    //load claims
                    loadClaims();
                } 
                else {
                    console.log("Failed to refresh tokens.")
                    this.loggedIn = false;
                    //go to auth
                    // this.goToAuth();
                }
            }

            this.setNewTokens(accessToken, refreshToken);
            return { accessToken };
        },
        goToAuth(){
            let url = window.location.href.indexOf('localhost') > -1 ? 'http://localhost:5173' : 'https://auth.easyworship.com';
            window.location.href = url + "/?redirect=" + encodeURIComponent(window.location.href) + "&appKey=" + import.meta.env.VITE_APP_KEY;
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },
        getRoles() {
            return this.roles;
        },
        setNewTokens(accessToken, refreshToken) {
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;
          },
          getAccessToken() {
            return this.accessToken;
          },
          getRefreshToken() {
            return this.refreshToken;
          },
        logout() {
            let accessName = import.meta.env.VITE_APP_KEY;
            this.setNewTokens(undefined, undefined);
            this.loggedIn = false;
            
            // Clear the cookies with proper attributes
            document.cookie = `accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=easyworship.com; secure;`;
            document.cookie = `refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=easyworship.com; secure;`;
            document.cookie = `refreshToken79B25C2A49FE4E0B9ED55184F8921788=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            document.cookie = '79B25C2A49FE4E0B9ED55184F8921788=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            document.cookie = `${accessName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=easyworship.com; secure;`;
            document.cookie = 'accessTokenExpiration=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=easyworship.com; secure;';
            document.cookie = 'refreshTokenExpiration=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=easyworship.com; secure;';
            
            
            // completely nuke all the cookies
            let cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=easyworship.com; secure;`;
            }
            


            //go to auth
            this.checkAuth();
        },
    },
});