import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from "@apollo/client/link/context";
import { useAuthStore } from './src/stores/auth.js';

let authStore;
setTimeout(() => {
    authStore = useAuthStore();
}, 1);

// Define the initial base URI
let baseUri = import.meta.env.VITE_GRAPHQL_API_URL + '/graphql/';

// Create a function to update the URI
function updateUri ( client, uri )
{
    const newLink = new createHttpLink( {
        uri: uri,
    } );

    client.setLink( newLink );
}

const organizationsLink = new createHttpLink( {
    uri: `${ baseUri }organizations/`,
} );

const productsLink = new createHttpLink( {
    uri: `${ baseUri }products/`,
} );

const adminLink = new createHttpLink( {
    uri: `${ baseUri }admin/`,
} );

const authLink = setContext( async ( _, { headers } ) => {
    let auth;

    //try getting the auth from the auth store
    await authStore.checkAuth();
    let token = await authStore.getAccessToken();

    auth = token ? `Bearer ${ token }` : undefined;

    if ( auth == undefined && integratedBrowser.value != undefined )
    {
      return;
    }
    else
    {
      return {
        headers: {
          ...headers,
          Authorization: auth,
        },
      };
    }
    }
)

const organizationsClient = new ApolloClient( {
    link: authLink.concat(organizationsLink),
    cache: new InMemoryCache(),
} );

const productsClient = new ApolloClient( {
    link: authLink.concat(productsLink),
    cache: new InMemoryCache(),
} );

const adminClient = new ApolloClient( {
    link: authLink.concat(adminLink),
    cache: new InMemoryCache(),
} );

// Function to update the base URI
function updateBaseUri ( newBaseUri )
{
    baseUri = newBaseUri;
    updateUri( organizationsClient, `${ baseUri }organizations/` );
    updateUri( productsClient, `${ baseUri }products/` );
    updateUri( adminClient, `${ baseUri }admin/` );
}

export { organizationsClient, productsClient, adminClient, updateBaseUri };
