<script setup>
import { RouterLink, RouterView } from 'vue-router';
import Header from './components/Header.vue';
import { ref } from 'vue';
import DynamicDialog from 'primevue/dynamicdialog';
import 'primeflex/primeflex.css';
import { useTabsStore } from './stores/tabs';
import { useAuthStore } from './stores/auth';

const authStore = useAuthStore();
const tabStore = useTabsStore();

const routeClass = computed( () =>
{
  if ( tabStore.nolan )
  {
    return 'nolan';
  }
  if ( tabStore.chandler )
  {
    return 'chandler';
  }
  return '';
} );


const roles = computed( () =>
{
  return useAuthStore().roles;
} );

</script>

<template>
  <Header v-if="roles.length > 0 && roles.indexOf('ProducerAccountAdmin') === -1"></Header>
  <RouterView :class="() => { return routeClass }" />
  <DynamicDialog></DynamicDialog>
</template>

<style lang="scss" scoped>
.nolan {
  background-image: url('./assets/nolan.png') !important;
  height: 100%;
  background-position: top center;
  background-attachment: fixed;
  border-radius: 0px !important;
}

.chandler {
  background-image: url('./assets/chandler.jpg') !important;
  height: 100%;
  background-position: top center;
  background-attachment: fixed;
  border-radius: 0px !important;
}
</style>
